<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <v-flex class="scrollbarMenu" xs12 md6 lg3 v-for="card in cards" :key="card.index">
        <v-card
          hover
          :to="{
            name: 'inseriratendimento',
            query: {
              solicitation: card.title,
              type: card.type,
              request: card.request,
            },
          }"
          class="contrast"
        >
          <v-list-item three-line>
            <v-icon medium class="white--text">{{ card.icon }}</v-icon>
            <v-list-item-content class="ml-3 white--text">
              <v-list-item-subtitle style="color: rgba(255, 255, 255, 0.8)"
                >SOLICITAR</v-list-item-subtitle
              >
              <v-list-item-title class="headline mb-1">{{
                card.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-flex>
      <atendimento-listar />
    </v-layout>

    <v-row v-else dense class="fitContainer pa-1">
      <v-tabs
        v-model="selectedTab"
        background-color="transparent"
        grow
        centered
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-create">Solicitar</v-tab>
        <v-tab href="#tab-list">Histórico</v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab" style="width: 100%">
        <v-tab-item :value="'tab-create'">
          <v-col cols="12" v-for="card in cards" :key="card.index" class="pa-1">
            <v-card
              elevation="2"
              :to="{
                name: 'inseriratendimento',
                query: {
                  solicitation: card.title,
                  type: card.type,
                  request: card.request,
                },
              }"
              class="contrast"
            >
              <v-list-item three-line>
                <v-icon medium class="white--text">{{ card.icon }}</v-icon>
                <v-list-item-content class="ml-3 white--text">
                  <v-list-item-subtitle style="color: rgba(255, 255, 255, 0.8)"
                    >SOLICITAR</v-list-item-subtitle
                  >
                  <v-list-item-title class="headline mb-1">{{
                    card.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-tab-item>

        <v-tab-item :value="'tab-list'">
          <atendimento-listar />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import atendimentoListar from "./atendimentoListar";

export default {
  name: "Atendimento",
  components: { atendimentoListar },
  data: () => ({
    cards: [],
    selectedTab: "active",
  }),
  methods: {
    ...mapMutations(["showmsg"]),
  },
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),
  },
  beforeMount() {
    if (
      this.$route.params.parameters &&
      this.$route.params.parameters.selectedTab
    )
      this.selectedTab = this.$route.params.parameters.selectedTab;

    let caseTypes = this.user.menu
      .filter((menu) => menu.id == this.$store.state.currentMenu.id)
      .map((menu) => menu.subMenus)
      .reduce(Array)
      .map((menu) => ({
        title: menu.title,
        icon: menu.icon,
        type: menu.options,
      }));

    this.cards = Array.from(caseTypes);
    let index = this.cards.findIndex(
      (menu) => menu.title === "ATENDIMENTOS - USUÁRIOS"
    );
    if (index && index > -1) {
      this.cards.splice(index, 1);
    }
  },
};
</script>
