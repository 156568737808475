var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!this.$route.meta.hideMenu)?_c('v-navigation-drawer',{staticClass:"scrollbarMenu customShadow",attrs:{"mini-variant":_vm.menu.leftmini,"mini-variant-width":80,"floating":"","app":"","hide-overlay":"","dark":"","color":"primary","mobile-break-point":_vm.window.mobileSize},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('label',{staticClass:"white--text",staticStyle:{"font-size":"small"}},[_vm._v("Versão: "+_vm._s(_vm.appBuildVersion))])])]},proxy:true}],null,false,452664500),model:{value:(_vm.menul),callback:function ($$v) {_vm.menul=$$v},expression:"menul"}},[_c('v-list',{staticClass:"layout column"},[_c('v-col',{staticClass:"text-start pt-1",attrs:{"cols":"12"}},[_c('img',{style:(_vm.menu.leftmini ? 'width: 50px' : 'width: 70px'),attrs:{"src":"/img/icons/logos/xipp-rh-protegido-logo-redondo.png"}}),_c('img',{style:(_vm.menu.leftmini ? 'margin-left: -10px; width: 70px' : 'margin-left: -25px; width: 170px;'),attrs:{"src":"/img/icons/logos/xipp_logo_horizontal_branco_fundo_transparente.png"}})]),_vm._l((_vm.items),function(item,index){return _c('div',{key:item.title + '_' + index,staticClass:"spaceLeftDrawer"},[(item.type === 'EXPAND')?_c('v-list-group',{key:item.id,attrs:{"no-action":"","color":"secundary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',{attrs:{"title":item.title}},[_c('v-icon',{staticClass:"font-weight-bold",attrs:{"id":"icon_menu"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},_vm._l((item.subMenus),function(subMenu){return _c('v-list-item',{key:subMenu.id,class:_vm.currentMenu() && subMenu.id === _vm.currentMenu().id
              ? 'selectedItem'
              : '',on:{"click":function($event){return _vm.link(subMenu)}}},[_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.currentMenu() && subMenu.id === _vm.currentMenu().id
                  ? 'menuLeft primary--text'
                  : 'menuLeft'},[_vm._v(_vm._s(subMenu.title)+" ")])],1)],1)}),1):_c('v-list-item',{class:_vm.currentMenu() && item.id === _vm.currentMenu().id ? 'selectedItem' : '',on:{"click":function($event){return _vm.link(item)}}},[_c('v-list-item-avatar',{attrs:{"title":item.title}},[_c('v-icon',{class:_vm.currentMenu() && item.id === _vm.currentMenu().id
                ? 'primary--text'
                : '',attrs:{"id":"icon_menu"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"menuLeft",class:_vm.currentMenu() && item.id === _vm.currentMenu().id
                ? 'menuLeft primary--text'
                : 'menuLeft'},[_vm._v(_vm._s(item.title))])],1)],1)],1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }