import axios from 'axios';
import $store from "../store/store";

const TOKEN_URI = '/oauth/token';
const USER_PATH = '/user/';
const CURRENT_USER_URI = '/internal_auth';
const CREATE_USER_FIRST_ACCESS_URI = '/user/createUserFirstAccess';
const CREATE_USER_BY_MASTER_URI = '/user/createUserByMasterUser';
const FORGOT_USER_PASSWORD_URI = '/user/forgotPassword';
const FIND_ALL_USERS_BY_FINANCIAL_GROUP = '/user/findAllByFinancialGroupExternalID';
const FIND_ALL_USERS_BY_FINANCIAL_GROUP_CONTRACT = '/user/findAllByFinancialGroupContract';
const FIND_ALL_DOCTOR_USERS_BY_FINANCIAL_GROUP = '/user/findAllDoctorUsersByFinancialGroupExternalID';
const EXISTS_EMAIL_LOGIN = '/user/existsByLogin/';
const EXISTS_EMAIL_LOGIN_AND_APP_CODE = '/user/existsByLoginAndAppCodeAndHasFinancialGroup/';
const FIND_MENU_URI = '/menu/loadMenu/';
const FIND_PROFILES_BY_APP = '/app/findActiveProfilesByAppCode/';
const USE_TERMS_PATH = '/template/getTemplateCurrentUser/'
const PROFILE_PICTURE_URI = '/user/profilePicture/';
const FINANCIAL_GROUP_BY_ID = '/api/odoo/financial_group/'
const FIND_ALL_USERS_BY_APP_AND_USER = '/user/findAllByAppCodeAndUser';
const LOGIN_AS_ANOTHER_USER = '/auth/auth/loginAsAnotherUser';

const USER_ROLE_DESCRIPTION = "COMPANY";

const {
    VUE_APP_SECURITY_SERVICE_CLIENT_ID,
    VUE_APP_SECURITY_SERVICE_CLIENT_SECRET,
    VUE_APP_SECURITY_SERVICE_URL,
    VUE_APP_ACCOUNT_URL,
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;

const tratarMensagemErroLogin = (error) => {
    let mensagem = "Acesso Inválido";

    if (error.response.data.error != null) {
        if (error.response.data.message == "User Access Validation Exception" ||
            error.response.data.message == "Contract permissions from company user isn't present. Check Odoo: financial group, contract and subcontract.") {
            mensagem = 'ACCESS_VALIDATION_ERROR_MESSAGE'
        } else if (error.response.data.message == 'User Terms was not accepted') {
            mensagem = 'O Termo de Uso não foi aceito.'
        } else if (error.response.data.message == 'Account permanently locked.') {
            mensagem = 'Usuário bloqueado.'
        } else if (error.response.data.message == 'User Social Id Not Exists') {
            mensagem = 'USER_SOCIAL_ID_NOT_EXISTS'
        } else if (error.response.data.message == 'Valid Account But No User') {
            mensagem = 'VALID_ACCOUNT_BUT_NO_USER'
        } else if (error.response.data.message == 'Account Login Not Found') {
            mensagem = 'ACCOUNT_LOGIN_NOT_FOUND'
        } else if (error.response.data.message == "Financial Group is not present or active.") {
            mensagem = "FINANCIAL_GROUP_NOT_PRESENT_OR_ACTIVE"
        } else if (error.response.data.message == "RH Protegido is disabled access in app configuration.") {
            mensagem = "RH_PROTEGIDO_IS_DISABLED_APP_CONFIGURATION"
        } else if (error.response.status == 500) {
            mensagem = "Erro ao acessar servidor"
        }
    }

    return mensagem;
}

const loginSecurityService = (user) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${TOKEN_URI}`;
    const client_credentials = btoa(VUE_APP_SECURITY_SERVICE_CLIENT_ID+":"+VUE_APP_SECURITY_SERVICE_CLIENT_SECRET);

    return axios({
        url,
        method: 'post',
        params: {
            grant_type: "password",
            username: user.login,
            password: user.password
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Basic ' + client_credentials
        },
    }).then(response => response.data).catch((error) => {});
};

const getCurrentUser = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${CURRENT_USER_URI}/nocache`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch(error => {
        return {
            error: true,
            message: tratarMensagemErroLogin(error)
        }
    });
};

const createUserFirstAccess = (user) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${CREATE_USER_FIRST_ACCESS_URI}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            name : user.name,
            login : user.login,
            email : user.email,
            cnpj : user.cnpj,
            password : user.password,
            appCode : VUE_APP_SECURITY_SERVICE_CLIENT_ID,
            userRoleDescription : USER_ROLE_DESCRIPTION
        },
    }).then(response => response.data);
};

const createUserByMasterUser = (user, token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${CREATE_USER_BY_MASTER_URI}`;

    let contractsIDs = [];

    if(user.contracts) {
        for (let i = 0; i < user.contracts.length; i++) {
            contractsIDs.push(user.contracts[i].id);
        }
    }

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token
        },
        data: {
            name : user.name,
            login : user.login,
            email : user.email,
            cpf : user.cpf,
            password : user.password,
            profileIds: user.profiles,
            externalContractIds: contractsIDs,
            contractBag: user.contractBag,
            appCode: VUE_APP_SECURITY_SERVICE_CLIENT_ID,
            userRoleDescription: USER_ROLE_DESCRIPTION,
            callback: window.location.origin + '/accountLoginCallback',
            accountUrl: VUE_APP_ACCOUNT_URL + '/serviceCreatePassword'
        },
    }).then(response => response.data);
};

const updateUser = (user, token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USER_PATH}`;

    let contractsIDs = [];

    if(user.contracts) {
        for (let i = 0; i < user.contracts.length; i++) {
            contractsIDs.push(user.contracts[i].id);
        }
    }

    return axios({
        url: url + user.id,
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token
        },
        data: {
            name : user.name,
            login : user.login,
            email : user.email,
            cpf : user.cpf,
            active: user.active,
            profileIds: user.profiles,
            externalContractIds: contractsIDs,
            contractBag: user.contractBag,
            appCode: VUE_APP_SECURITY_SERVICE_CLIENT_ID,
            userRoleDescription: USER_ROLE_DESCRIPTION
        },
    }).then(response => response.data);
};

const updateUserStatus = (user, token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USER_PATH}`;
    return axios({
        url: url + user.id,
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token
        },
        data: {
            active: user.active
        },
    }).then(response => response.data);
};

const forgotUserPassword = (user) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FORGOT_USER_PASSWORD_URI}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            login : user.email,
            appCode : VUE_APP_SECURITY_SERVICE_CLIENT_ID
        },
    }).then(response => response.data);
};


const findAllUsersByFinancialGroup = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_ALL_USERS_BY_FINANCIAL_GROUP}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};

const findAllUsersByFinancialGroupAndContract = (token,contractId) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_ALL_USERS_BY_FINANCIAL_GROUP_CONTRACT}?contractId=${contractId}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};

const findAllDoctorUsersByFinancialGroup = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_ALL_DOCTOR_USERS_BY_FINANCIAL_GROUP}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};

const findActiveProfilesByApp = async (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_PROFILES_BY_APP}`;

    return axios({
        url: url + VUE_APP_SECURITY_SERVICE_CLIENT_ID,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};


const getUseTerms = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USE_TERMS_PATH}`;

    return axios({
        url: url + 1,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};
const getMenu = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_MENU_URI}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};

const getProfilePicture = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${PROFILE_PICTURE_URI}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};

const updateProfilePicture = (data) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${PROFILE_PICTURE_URI}`;

    return axios({
        url: url,
        method: 'put',
        headers: {
            Authorization: 'Bearer ' + $store.state.session.tokenSecurityService.access_token,
            'Content-Type': 'application/json'
        },
        data: {
            imgContent: data,
        },
    });
};

const getUserById = (id, token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USER_PATH}`;
    return axios({
        url: url + id,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token
        }
    }).then(response => response.data);
};


const existsEmaillLogin = (login) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${EXISTS_EMAIL_LOGIN}` + login;

    return axios({
        url: url,
        method: 'get',
    })
};


const existsEmaillLoginAndAppCode = (login) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${EXISTS_EMAIL_LOGIN_AND_APP_CODE}` + login + '/' + VUE_APP_SECURITY_SERVICE_CLIENT_ID;
    const client_credentials = btoa(VUE_APP_SECURITY_SERVICE_CLIENT_ID+":"+VUE_APP_SECURITY_SERVICE_CLIENT_SECRET);

    return axios({
        url: url,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + client_credentials
        }
    })
};

const findFinancialGroupById = (id, token) => {
    const url = `${process.env.VUE_APP_SALESFORCE_SERVICE_URL}${FINANCIAL_GROUP_BY_ID}` + id;
    return axios({
        url: url,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token
        }
    }).then(response => response.data);
};

const findAllUsersByAppCodeAndUser = (token, userNameOrLogin) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_ALL_USERS_BY_APP_AND_USER}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
        params:{
            appCode: VUE_APP_SECURITY_SERVICE_CLIENT_ID,
            userNameOrLogin: userNameOrLogin
        }
    });
};

const loginAsAnotherUser = (token, idUserToLoginWith) => {
    const url = `${VUE_APP_API_URL}${LOGIN_AS_ANOTHER_USER}/${idUserToLoginWith}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        }
    });
};

const logoutSession = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USER_PATH}logout`;
    return axios({
        url: url ,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data);
};

export {
    loginSecurityService,
    getCurrentUser,
    createUserFirstAccess,
    createUserByMasterUser,
    forgotUserPassword,
    findAllUsersByFinancialGroup,
    findAllUsersByFinancialGroupAndContract,
    findAllDoctorUsersByFinancialGroup,
    updateUser,
    findActiveProfilesByApp,
    getUseTerms,
    getMenu,
    getProfilePicture,
    updateProfilePicture,
    getUserById,
    updateUserStatus,
    existsEmaillLogin,
    existsEmaillLoginAndAppCode,
    findFinancialGroupById,
    findAllUsersByAppCodeAndUser,
    loginAsAnotherUser,
    logoutSession
};
