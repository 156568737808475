<template>
  <v-card flat class="pa-0" elevation="0">
    <v-text-field label="Pesquisar" v-model="search" :loading="busy">
      <template v-slot:append>
        <v-btn icon @click="searchByNome">
          <v-icon>fas fa-search</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <ProgressiveLoader :disabled="busy">
      <v-data-table
        loading-text="Carregando itens..."
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
        :headers="headers"
        :items="searchById()"
        :loading="loading"
      >

        <template v-slot:no-data>Não existe subcontratos cadastrados!</template>

      </v-data-table>
    </ProgressiveLoader>
  </v-card>
</template>

<script>
import ContractService from "../../services/odoo/ContractService";
import ProgressiveLoader from "@/components/progressive-loader";
import { mapMutations } from "vuex";

export default {
  name: "SubContractListSearcher",
  props: {
    parentContract: {
      type: Number,
      required: true
    }
  },
  created() {
    this._contractService = new ContractService();
    this.getContratos();
  },
  components: { ProgressiveLoader },
  data: () => ({
    headers: [
      { text: "Código de Sub", value: "policy" },
      { text: "Empresa", value: "accountName" },
      { text: "CNPJ", value: "accountCNPJ" },
      //{ text: "Benefício", value: "benefit" },
      //{ text: "Operadora", value: "carrierName" },
      { text: "Inicio Vigência", value: "startDate" },
      { text: "Fim Vigência", value: "carrierDataCancelation" }
    ],
    subContracts: [],
    search: "",
    busy: false,
    noMatches: false,
    loading: false,
  }),
  methods: {
    ...mapMutations(["showmsg"]),
    configureItem(records) {
      records = records.filter(f => f.subcontract == true);

      if (records.length) {
        records.forEach(subContract => {
          this.subContracts.push({
            id: subContract.id,
            contractNumber: subContract.subcontract_number,
            accountName: subContract.contract_owner_id? subContract.contract_owner_id.name : " ",
            policy: subContract.subcontract_number,
            accountCNPJ: subContract.cnpj,
            benefit: subContract.benefit_id,
            carrierName: subContract.carrier_id? subContract.carrier_id.xipp_commercial_name : " ",
            startDate: this.formatDate(subContract.start_date),
            carrierDataCancelation: this.formatDate(subContract.carrier_data_cancelation)
          });
        });
        this.orderConfiguredItems();
      } else {
        this.noMatches = true;
        return;
      }
    },
    orderConfiguredItems(){
      this.subContracts.sort((subContractA, subContractB)=>{
        return subContractA.policy.localeCompare(subContractB.policy);
      })
    },
    getContratos() {
      this.loading = true;
      this.busy = true;
      this.subContracts = [];
      const params = new URLSearchParams();
      params.append('fields', ['id', 'subcontract_number', 'contract_owner_id', 'cnpj',
        'benefit_id', 'carrier_id', 'start_date', 'carrier_data_cancelation', 'subcontract'
      ]);
      this._contractService
        .findSubContractsByFilters(this.parentContract, params)
        .then(res => {
          this.configureItem(res.data);
        })
        .catch(reason => {
          this.showmsg({
            text: "Erro ao carregar subcontratos",
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
          this.busy = false;
        });
    },

    searchByNome() {
      var contratos = this.searchById();
      if (contratos.length == 0) {
        let rq = {
          query: this.search
        };

        this.busy = true;
        this._contractService
          .FindSubContractsData(this.parentContract, rq)
          .then(res => {
            if (res.data && res.data.records && res.data.records.length > 0) {
              this.configureItem(res.data.records);
              this.searchById();
            }
          })
          .finally(() => (this.busy = false));
      }
    },

    searchById() {
      if (this.search && this.search.length > 0) {
        if (this.subContracts && this.subContracts.length > 0) {
          if (this.subContracts.some(w => w.contractNumber != null)) {
            return this.subContracts.filter(f =>
              f.contractNumber.includes(this.search)
            );
          }
        }
      } else {
        return this.subContracts;
      }

      return [];
    },
    formatDate(date){
      return date ? Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(date)) : " ";
    }
  }
};
</script>
