<template>
  <v-container style="overflow: auto !important;" fluid>
    <v-card>
      <v-card-text>
        <v-form ref="formTarget">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                disabled
                :items="typeList"
                v-model="target.type"
                label="Tipo"
                placeholder="Selecione o Tipo"
                outlined
              />
            </v-col>
            <v-col cols="12" md="6" >
              <v-autocomplete
                v-if="target.type === 'GRUPO_FINANCEIRO'"
                ref="comboFinancialGroups"
                v-model="financialGroupSelecteds"
                class="pt-0 pb-0"
                small-chips
                deletable-chips
                return-object
                outlined
                :items="getAvailableFinancialGroups"
                :loading="loadingFinancialGroups"
                :disabled="loadingFinancialGroups"
                label="Grupo Financeiro"
                :rules="[formrules.required]"
                item-text="name"
                item-value="id"
                :readonly="!sfUser"
              />

              <v-autocomplete
                v-if="target.type === 'SEXO'"
                :rules="[formrules.required]"
                ref="comboSex"
                v-model="target.identifier"
                class="pt-0 pb-0"
                small-chips
                deletable-chips
                return-object
                outlined
                :items="sexOptions"
                label="Sexo"
                item-text="text"
                item-value="value"
              />

              <v-text-field
                v-else-if="target.type === 'IDADE_MAIOR_QUE' || target.type === 'IDADE_MENOR_QUE'"
                :rules="[formrules.required]"
                type="number"
                v-model="target.identifier"
                label="Identificador"
                outlined
                placeholder="Id externo do alvo quando necessário: Ex: Id do Contrato, Id do grupo Financeiro"
              />

              <v-autocomplete
                v-else-if="target.type === 'SITUACAO_BENEFICIARIO'"
                :rules="[formrules.required]"
                v-model="target.identifier"
                outlined
                :items="beneficiarySituationTypes"
                label="Situação do Beneficiário"
                item-text="text"
                item-value="value"
              />
              <v-autocomplete
                ref="comboContracts"
                v-else-if="target.type === 'CONTRATO'"
                :rules="[formrules.required]"
                small-chips
                deletable-chips
                return-object
                v-model="contractSelecteds"
                outlined
                :items="getAvailableContracts"
                :loading="loadingContracts"
                :disabled="loadingContracts"
                label="Contrato"
                item-text="text"
                item-value="id"
                class="pt-0 pb-0"
              />

              <v-autocomplete
                ref="comboSubContracts"
                v-else-if="target.type === 'SUBCONTRATO'"
                :rules="[formrules.required]"
                small-chips
                deletable-chips
                return-object
                v-model="subcontractSelecteds"
                outlined
                :items="getAvailableSubcontracts"
                :loading="loadingSubcontracts"
                :disabled="loadingSubcontracts"
                label="Subcontrato"
                item-text="text"
                item-value="id"
                class="pt-0 pb-0"
              />

              <v-autocomplete
                v-else-if="target.type === 'OPERADORA'"
                :rules="[formrules.required]"
                v-model="carriersSelected"
                outlined
                :items="carriers"
                label="Operadora"
                item-text="name"
                item-value="id"
                small-chips
                deletable-chips
                return-object
              />
            </v-col>
          </v-row>

        <v-row>
          <v-col cols="6">
            <v-btn
              large
              color="secondary"
              light
              @click="cancelar()"
              class="black--text"
            >Voltar</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              large
              block
              color="primary"
              :loading="busy"
              :disabled="busy"
              @click="validatePost()"
            >Salvar
            </v-btn
            >
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import SocialNetworkService from "../../../services/socialnetwork/SocialNetworkService";
import OdooService from "../../../services/odoo/ContractService";
import CarrierService from "../../../services/odoo/CarrierService";
import {formrules} from "../../../common/formrules";

export default {
  name: "target-public-inserir",
  data: () => ({
    formrules,
    target: {
      type: null,
      id: 0,
      identifier: null,
      feedItemId: null,
    },
    allContractPermitteds: [],
    financialGroups: [],
    financialGroupSelecteds: [],
    loadingFinancialGroups: false,
    contracts: [],
    contractSelecteds: [],
    loadingContracts: false,
    subcontracts: [],
    subcontractSelecteds: [],
    carriers: [],
    carriersSelected: null,
    loadingSubcontracts: false,
    _odooService: null,
    busy: false,
    typeList: [],
    beneficiarySituationTypes: [
      { text: 'ATIVOS', value: 'ATIVO' },
      { text: 'INATIVOS', value: 'INATIVO' },
      { text: 'TODOS', value: 'ALL' }
    ],
    sexOptions: [
      { text: 'Masculino', value: 'MALE' },
      { text: 'Feminino', value: 'FEMALE' },
      { text: 'Todos', value: 'ALL' }
    ],
    contractSearchFields: ['id','name','benefit_id','carrier_id','carrier_id.xipp_commercial_name','contract_owner_id', 'contract_owner_id.legal_name', 'policy'],
    sfUser: false,
    user: {},
    urlPublish: '/publicacao',
    test: null,
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user","getSelectedFinancialGroups","getSelectedContracts","getAvailableContracts",
      "getAvailableSubcontracts","getSelectedSubcontracts","getSelectedCarriers","getAvailableFinancialGroups","getSelectedItem"]),
  },

  watch: {
    financialGroupSelecteds() {
      this.target.identifier = this.financialGroupSelecteds.id === 0 ? 'ALL' : this.financialGroupSelecteds.id.toString();
    },
    contractSelecteds() {
      this.target.identifier = this.contractSelecteds.id === 0 ? 'ALL' : this.contractSelecteds.id.toString();
    },
    subcontractSelecteds() {
      this.target.identifier = this.subcontractSelecteds.id === 0 ? 'ALL' : this.subcontractSelecteds.id.toString();
    },
    carriersSelected() {
      this.target.identifier = this.carriersSelected.id === 0 ? 'ALL' : this.carriersSelected.id.toString();
    },
  },

  methods: {
    ...mapMutations(["showmsg", "loading"]),
    ...mapActions(["setSelectedFinancialGroups","setAvailableFinancialGroups",
      "setSelectedContracts","setAvailableContracts",
      "setSelectedSubContracts","setAvailableSubcontracts", "setSelectedCarriers"]),
    validatePost() {
      if(!this.$refs.formTarget.validate()) {
        return;
      }

      let isValid = true;

      if (this.target.type == null || this.target.type == "") {
        isValid = false;
        this.showmsg({
          text: "Necessário informar o Tipo!",
          type: "error",
        });
      } else if (!this.target.feedItemId || this.target.feedItemId < 0) {
        isValid = false;
        this.showmsg({
          text: "Erro na página. Volte para tela anterior!",
          type: "error",
        });
      }

      if (isValid) {
        this.savePost();
      }
    },
    savePost() {
      this.loading(true);
      let post = {
        feedItemId: this.target.feedItemId,
        identifier: this.target.identifier,
        type: this.target.type,
        id: this.target.id,
        fixed: false,
      };
      if (this.target.id > 0) {
        this._socialNetworkService
          .UpdateTarget(post)
          .then((_res) => {
            if (_res && _res.data && _res.data.id > 0) {
              this.showmsg({
                text: "Alterado com sucesso!",
                type: "success",
              });
              if(this.target.type === "GRUPO_FINANCEIRO"){
                this.setSelectedFinancialGroups(this.financialGroupSelecteds);
              }
              if (this.target.type === "CONTRATO") {
                this.setSelectedContracts(this.contractSelecteds);
              }
              if (this.target.type === "SUBCONTRATO") {
                this.setSelectedSubContracts(this.subcontractSelecteds);
              }
            } else {
              this.showmsg({
                text: "Erro ao salvar o público alvo!",
                type: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error)
            this.showmsg({
              text: "Erro ao salvar o público alvo!",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
          });
      } else {
        this._socialNetworkService
          .SaveTarget(post)
          .then((_res) => {
            if (_res && _res.data && _res.data.id > 0) {
              this.target.id = _res.data.id;
              this.showmsg({
                text: "Incluído com sucesso!",
                type: "success",
              });
              this.cancelar()
            } else {
              this.showmsg({
                text: "Erro ao incluir o público alvo!",
                type: "error",
              });
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao incluir o público alvo!",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
          });
      }
    },
    cancelar() {
      this.$router.push(`${this.urlPublish}/${this.target.feedItemId}`);
    },
    async init() {
      if (this.$route.query.id || this.$route.params.id) {
        this.target.id = this.$route.query.id
          ? this.$route.query.id
          : this.$route.params.id;
        if (this.target.id > 0) {
          this.loading(true);
          await this._socialNetworkService
            .getTarget(this.target.id)
            .then(async (_res) => {
              if (_res && _res.data && _res.data.id) {
                this.target.type = _res.data.type;
                this.target.identifier = _res.data.identifier;
                this.target.feedItemId = _res.data.feedItemId;
                if (this.target.type == 'GRUPO_FINANCEIRO') {
                  await this.loadAllFinancialGroups();
                } else if (this.target.type == 'CONTRATO') {
                  await this.loadAllContractsByFinancialGroup();
                } else if (this.target.type == 'SUBCONTRATO') {
                  await this.loadAllSubContractsByContract();
                } else if (this.target.type == 'OPERADORA') {
                  await this.loadAllCarriers();
                  this.carriersSelected = { id: parseInt(this.target.identifier), name : '' }
                }
              }
            })
            .catch(() =>
              this.showmsg({
                text: "Erro ao carregar o público alvo!",
                type: "error",
              })
            )
            .finally(() => {
              this.loading(false);
            });
        } else {
          this.target = this.getSelectedItem;
        }
      }

      if (this.$route.query.feedItemId || this.$route.params.feedItemId) {
        this.target.feedItemId = this.$route.query.feedItemId
          ? this.$route.query.feedItemId
          : this.$route.params.feedItemId;
      }
    },
    getTypeList() {
      this.typeList = [];
      this.loading(true);
      this._socialNetworkService
        .getTargetTypeList()
        .then((_res) => {
          if (_res && _res.data && _res.data.length > 0) {
            _res.data.forEach((element) => {
              this.typeList.push({
                text: element.label,
                value: element.value,
              });
            });
            var resTypeList = this.typeList.map(elem => elem.value)
            if(!resTypeList.includes('GRUPO_FINANCEIRO')){
              this.typeList.push({
                text: "GRUPO FINANCEIRO",
                value: "GRUPO_FINANCEIRO",
              });
            };
            if(!resTypeList.includes('OPERADORA')){
              this.typeList.push({
                text: "OPERADORA",
                value: "OPERADORA",
              });
            };
          }
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar o lista de tipos!",
            type: "error",
          })
        )
        .finally(() => {
          this.loading(false);
        });
    },
    loadUrlCases(){
      const {hideMenu} = this.$route.meta
      if(hideMenu){
        this.urlPublish = '/publicacao-salesforce'
      }
    },

    async loadAllFinancialGroups() {
      this.loadingFinancialGroups = true;
      if(this.sfUser) {
        const params = new URLSearchParams();
        params.append('fields', ['id','name']);
        await this._odooService.FindAllFinancialGroupV2(params)
          .then(response => {
            if(response && response.data) {
              response.data.unshift({name: 'Todos', id: 0})
              this.setAvailableFinancialGroups(response.data);
              this.loadingFinancialGroups = false;
            }
          })
          .catch(() => {
            this.loadingFinancialGroups = false;
            this.showmsg({text: "Erro ao tentar carregar os grupos financeiros.", type: "error"})
          });
      } else {
        let financialGroupId = null;
        if(this.paramId && parseInt(this.paramId) === 0) {
          const session = this.$util.getSession();
          financialGroupId = (session && session.financialGroup && session.financialGroup.id) ? session.financialGroup.id : null;
        }

        if(financialGroupId) {
          await this._odooService.FindAllFinancialGroupById(financialGroupId)
              .then(response => {
                if(response && response.data && response.data) {
                  this.financialGroups.push(response.data);
                  this.loadingFinancialGroups = false;
                }
              })
              .catch(() => {
                this.loadingFinancialGroups = false;
                this.showmsg({text: "Erro ao tentar carregar os grupos financeiros.", type: "error"})
              });

          if(this.financialGroups.length) {
            this.loadingFinancialGroups = false;
            await this.loadAllContractsByFinancialGroup();
          }
        }
      }
      this.loadingFinancialGroups = false;
    },

    async loadAllContractsByFinancialGroup() {
      this.loadingContracts = true;
      let firstContract = null;
      if(this.contracts.length) {
        firstContract = this.contracts[0];
      }

      this.contracts = [];
      this.contracts.push(firstContract);

      let firstSubContract = null;
      if(this.subcontracts.length) {
        firstSubContract = this.subcontracts[0];
      }

      this.subcontracts = [];
      this.subcontracts.push(firstSubContract);
      const hasFinancialGroup = this.getSelectedFinancialGroups && this.getSelectedFinancialGroups.id !== undefined && this.getSelectedFinancialGroups.id != null && this.getSelectedFinancialGroups.id !== 0;
      if(hasFinancialGroup || !this.sfUser) {
        const params = new URLSearchParams();
        params.append('subcontract', 'false');
        if(hasFinancialGroup){
          params.append('financialGroupIds', this.getSelectedFinancialGroups.id);
        } else {
          params.append('financialGroupIds', this.user.financialGroup.id);
        }
        params.append('fields', this.contractSearchFields);
        await this._odooService.FindAllContractV2(params)
          .then(response => {
            let contractsLoaded = [];
            if(response && response.data) {
              response.data.forEach(contract => {
                if(!this.sfUser && this.allContractPermitteds.some(contractPermitted => contractPermitted.id === contract.id)) {
                  contractsLoaded.push(contract);
                } else if (this.sfUser) {
                  contractsLoaded.push(contract);
                }
              })
            }

            if (contractsLoaded.length) {
              contractsLoaded = contractsLoaded.map((item) => ({
                ...item,
                disabled: false,
                text: this.formatContractText(item),
              }));
            }
            this.contracts = this.contracts.concat(contractsLoaded);
            contractsLoaded.unshift({text: 'Todos', id: 0})
            this.setAvailableContracts(contractsLoaded);
            this.loadingContracts = false;
          })
          .catch(() => {
            this.loadingContracts = false;
            this.showmsg({text: "Erro ao tentar carregar os contratos.", type: "error"});
          });
      } else if(this.sfUser){
          this.setAvailableContracts([{text: 'Todos', id: 0}]);
      }
      this.loadingContracts = false;
    },

    async loadAllSubContractsByContract() {
      this.loadingSubcontracts = true;
      if(this.getSelectedContracts && this.getSelectedContracts.id !== 0) {
        this.loadingSubcontracts = true;
        const contractsParams = [this.getSelectedContracts];
        contractsParams.unshift();
        const params = new URLSearchParams();
        params.append('includeSubContracts', 'true');
        params.append('parentContractIds', this.getSelectedContracts.id);
        params.append('fields', this.contractSearchFields);
        await this._odooService.FindAllContractV2(params)
          .then(response => {
            let subcontractsLoaded = [];
            if(response && response.data) {
              subcontractsLoaded = response.data;
            }

            if (subcontractsLoaded.length) {
              subcontractsLoaded = subcontractsLoaded.map((item) => ({
                ...item,
                text: this.formatContractText(item),
              }));
            }
            this.subcontracts = this.subcontracts.concat(subcontractsLoaded);
            this.subcontracts.unshift({text: 'Todos', id: 0});
            this.setAvailableSubcontracts(this.subcontracts);
            this.loadingSubcontracts = false;
          })
          .catch(() => {
            this.loadingSubcontracts = false;
            this.showmsg({text: "Erro ao tentar carregar os subcontratos.", type: "error"});
          });
      } else {
        this.subcontracts.push({text: 'Todos', id: 0});
        this.setAvailableSubcontracts(this.subcontracts);
      }
      this.loadingSubcontracts = false;
    },

    formatContractText(contract){
      return (`${(contract.benefit_id ? contract.benefit_id : '-')} / ${(contract.carrier_id && contract.carrier_id.xipp_commercial_name ?
          contract.carrier_id.xipp_commercial_name : '-')} - ${contract.policy ? contract.policy : '-'} - ${contract.contract_owner_id && contract.contract_owner_id.legal_name ? contract.contract_owner_id.legal_name : '-'}`)
    },

    setSelectedTargets() {
      this.financialGroupSelecteds = this.getSelectedFinancialGroups;
      this.contractSelecteds = this.getSelectedContracts;
      this.subcontractSelecteds = this.getSelectedSubcontracts;
    },

    async loadAllCarriers() {
      const params = new URLSearchParams();
      params.append('fields', ['id','name']);
      await this._carrierService.findAllCarriersByFiltersV2(params).then(
      (response) => {
        if(response && response.data) {
          this.carriers = response.data;
        }
      }).catch(() => {
        this.showmsg({text: "Erro ao tentar carregar as operadoras.", type: "error"});
      });
    }

  },
  beforeCreate() {
    this._odooService = new OdooService();
    this._socialNetworkService = new SocialNetworkService();
    this._carrierService = new CarrierService();
  },
  async created() {
    const sessionContractsAndSubs = JSON.parse(localStorage.getItem('contract-users'));
    if(sessionContractsAndSubs && sessionContractsAndSubs.length) {
      this.allContractPermitteds = sessionContractsAndSubs.filter(contract => !contract.subcontract)
    }
    this.user = this.$util.getSession();
    this.sfUser = this.user.financialGroup ? false : true;
    this.paramId = this.$route.params.id;
    await this.init();
    this.getTypeList();
    this.loadUrlCases();
    this.setSelectedTargets();
  },

};
</script>
