<template>
    <v-container fluid fill-height class="calc-h90">
        <iframe
                :src="externalURL"
                :width="widthValue"
                :height="heightValue"
                :class="classValue"
                :style="styleValue"
                frameborder="0"
                scrolling="auto"
                allowtransparency
        ></iframe>
    </v-container>
</template>

<script>

    export default {
        name: "ExternalURL",
        props: {
            urlParam: null,
            classParam: null,
            styleParam: null,
            queryParam: null,
            widthParam: null,
            heightParam: null,
            titleParam: null,
        },
        computed: {
            externalURL() {
                let queryParam = this.queryParam ? this.queryParam : this.$route.params.queryParam ? this.$route.params.queryParam : '';
                if(this.$route.params && this.$route.params.urlParam && this.$route.params.urlParam.includes('home-rh-protegido')) {
                  const financialGroupId = this.$util.getSession() && this.$util.getSession().financialGroup
                      ? this.$util.getSession().financialGroup.id : null;
                  return `${this.$route.params.urlParam}${queryParam}&isRHProtegido=true&financialGroupId=${financialGroupId}`;
                }

                let hasNotificationConfigAuthority = this.$util.hasNotificationConfigDriveAuthority();
                let hasMenu = this.$util.hasMenu();
                return `${this.$route.params.urlParam}${queryParam}&hasNotificationConfigAuthority=${hasNotificationConfigAuthority}&hasMenu=${hasMenu}`;
            },
            classValue() {
                return this.classParam ? this.classParam : this.$route.params.classParam ? this.$route.params.classParam : '';
            },
            styleValue() {
                return this.styleParam ? this.styleParam : this.$route.params.styleParam ? this.$route.params.styleParam : '';
            },
            widthValue() {
                return this.widthParam ? this.widthParam : this.$route.params.widthParam ? this.$route.params.widthParam : '100%';
            },
            heightValue() {
                return this.heightParam ? this.heightParam : this.$route.params.heightParam ? this.$route.params.heightParam : '100%';
            },
            titleValue() {
                return this.titleParam ? this.titleParam : this.$route.params.titleParam ? this.$route.params.titleParam : '';
            },
            iconValue() {
                return this.iconParam ? this.iconParam : this.$route.params.iconParam ? this.$route.params.iconParam : '';
            },

        },
        beforeMount() {
            this.$route.meta.title = this.titleValue;
            this.$route.meta.icon = this.iconValue;
        },
    }

</script>

<style scoped>
.calc-h90 {
  height: calc(100% - 75px);
}
</style>
