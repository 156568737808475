/* eslint-disable */
import HttpService from './HttpService'

export default class CaseService {
  constructor() {
    this._httpService = new HttpService();
  }

  async FindAll() {
    return await this._httpService.get('/api/odoo/case');
  }

  async FindById(params) {
    return await this._httpService.get('/api/odoo/case', params );
  }

  async FindByIdV2(params) {
    return await this._httpService.get('/api/odoo/case/v2', params );
  }

  async FindByParam(path) {
    return await this._httpService.get('/api/odoo/case/v2' + path);
  }

  async FindByQueryParam(params) {
    return await this._httpService.get('/api/odoo/case', params);
  }

  async FindLimiteDateExternalProtocol() {
    return await this._httpService.get('/api/odoo/case/find_limite__date_external_protocol');
  }

  async GetOpenedCaseStatusConfiguration() {
    return await this._httpService.get('/api/salesforce/case/get_opened_case_status_configuration');
  }

  async FindAllByFiters(data) {
    return await this._httpService.get('/api/odoo/case/findAllByFilters' + data);
  }

  async Create(data) {
    return await this._httpService.post('/api/odoo/case', data);
  }

  async CreateCaseWithAttachment(data) {
    return await this._httpService.post('/api/odoo/case/case_with_attachment', data);
  }

  async CreateCaseEvent(data) {
    return await this._httpService.post('/api/odoo/case/case_event', data);
  }

  async FindbyDescribe() {
    if (!(sessionStorage.getItem("describe-case"))) {
      var describe = await this._httpService.get('/api/odoo/case/describe');
      sessionStorage.setItem('describe-case', JSON.stringify(describe.data));
    }
  }

  dsDescribe(_describe, name, campo) {
    if (
      _describe &&
      _describe.fields &&
      _describe.fields.some(s => s.name == campo)
    ) {
      var field = _describe.fields.filter(s => s.name == campo)[0];
      if (field) {
        if (field.picklistValues && field.picklistValues.length > 0) {
          if (field.picklistValues.some(s => s.value == name)) {
            return field.picklistValues.filter(s => s.value == name)[0].label;
          }
        }
      }
    }
    return name;
  }
}
