<template>
  <v-container fluid>
    <v-card>
      <v-card-title style="color: rebeccapurple; font-weight: bold">
        Visão Gerencial dos acessos
      </v-card-title>
      <v-card-text>
        <v-form id="searchForm" ref="searchForm" @submit.prevent="options.page=1; searchItems()">
          <v-row dense>
            <v-col cols="3">
              <v-select
                  :items="apps"
                  v-model="appCodes"
                  label="App"
                  item-value="code"
                  item-text="name"
                  placeholder="Selecione o App"
                  outlined
                  clearable
                  multiple
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="filter.name"
                label="Nome"
                outlined
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                  v-model="filter.email"
                  label="E-mail"
                  outlined
              />
            </v-col>
            <v-col cols="3">
              <v-select
                  :items="financialGroups"
                  :loading="createdLoading"
                  v-model="filter.financialGroupIds"
                  label="Grupo Financeiro"
                  item-value="id"
                  item-text="name"
                  placeholder="Selecione o Grupo Financeiro"
                  outlined
                  clearable
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <v-select
                  :items="profiles"
                  :loading="createdLoading"
                  v-model="filter.profileId"
                  label="Perfil"
                  item-value="id"
                  item-text="name"
                  clearable
                  placeholder="Selecione o Perfil"
                  outlined
              />
            </v-col>
            <v-col cols="3">
              <v-select
                  :items="status"
                  v-model="filter.active"
                  label="Status"
                  item-value="id"
                  item-text="name"
                  clearable
                  placeholder="Selecione o Status"
                  outlined
              />
            </v-col>
            <v-col cols="3">
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-col cols="6" class="text-left pt-1" v-if="items.length==0">
                </v-col>
                <v-col cols="6" class="text-right pt-1">
                  <v-btn
                      large
                      color="primary"
                      :loading="loadingSearch"
                      :disabled="loadingSearch"
                      type="submit"
                  >Pesquisar</v-btn
                  >
                </v-col>
                <v-col cols="6" class="text-left pt-1" v-if="items.length>0">
                  <v-btn
                      v-if="items.length>0"
                      large
                      color="primary"
                      :loading="loadingSearch"
                      :disabled="loadingSearch"
                      @click="generateReport"
                  >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>

      </v-card-text>
    </v-card>
    <br />
    <v-card>
      <v-card-text>
        <v-data-table
            id="search-result"
            loading-text="Pesquisando registros..."
            disable-filtering
            disable-sort
            :headers="headers"
            :items="items"
            :loading="loadingSearch"
            item-key="id"
            :server-items-length="totalElements"
            @update:page="searchItems"
            @update:items-per-page="searchItems"
            :options.sync="options"
        >
          <template v-slot:header>
          </template>
          <template v-slot:no-data>
            <v-alert
                :value="true"
                type="info"
                v-show="!loadingSearch"
                outlined
            >Nenhum item foi encontrado</v-alert>
          </template>
          <template v-slot:item.active="{ item }">
            <span v-if="item.active"> Sim </span>
            <span v-else> Não </span>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span v-if="item.createdAt"> {{ new Date(item.createdAt).toLocaleDateString() }} </span>
            <span v-else> - </span>
          </template>
          <template v-slot:item.useTermsAcceptedDate="{ item }">
            <span v-if="item.useTermsAcceptedDate"> {{ new Date(item.useTermsAcceptedDate).toLocaleDateString() }} </span>
            <span v-else> - </span>
          </template>
          <template v-slot:item.lastAccessDate="{ item }">
            <span v-if="item.lastAccessDate"> {{ new Date(item.lastAccessDate).toLocaleDateString() }} </span>
            <span v-else> - </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import ContractService from "@/services/odoo/ContractService";
import SecurityService from "@/services/security/SecurityService"
import {formrules} from "@/common/formrules";
import AuthService from "@/services/auth/AuthService";

export default {
  name: "userReport",
  data: () => ({
    filter: {
      appCodes: null,
      name: null,
      email: null,
      financialGroupIds: null,
      profileId: null,
      active: null
    },
    page: 1,
    itemsPerPage: 10,
    totalElements: 0,
    options:[],
    items: [],
    financialGroups: [],
    profiles: [],
    appCodes: [],
    apps: [{code: "dr_protegido", name: "DR Protegido"},{code: "rh_protegido", name: "RH Protegido"}],
    status: [{id: true, name: "Ativo"},{id: false, name: "Inativo"}],
    createdLoading: false,
    loadingSearch: false,
    rules: formrules,
    headers: [
      { text: "Grupo Financeiro", value: "financialGroup", cols: "6" },
      { text: "Nome", value: "name", cols: "6" },
      { text: "E-mail", value: "email", cols: "12", align: 'center' },
      { text: "Perfil", value: "profile", cols: "12", align: 'center' },
      { text: "Ativo", value: "active", cols: "6" },
      { text: "Data de Criação", value: "createdAt", cols: "6", align: 'center' },
      { text: "Aceito Em", value: "useTermsAcceptedDate", cols: "6", align: 'center' },
      { text: "Último Acesso", value: "lastAccessDate", cols: "6", align: 'center' },
    ]
  }),
  methods: {
    ...mapMutations(["showmsg"]),
    searchItems(){
      this.loadingSearch=true;
      this.items=[];
      this.filter.pageNumber = this.options.page-1;
      this.filter.pageSize = this.options.itemsPerPage;
      this.filter.appCodes = this.appCodes.length>0 ? this.appCodes.toString() : null;
      this._securityService.getUserReportData(this.filter)
      .then(response => {
        this.items = response.data.content;
        this.totalElements = response.data.totalElements;
      })
      .catch(() => this.showmsg({text: 'Ocorreu um erro ao pesquisar os usuários.', type: 'error'}))
      .finally(() => this.loadingSearch=false);

    },
    generateReport(){

      this.loadingSearch=true;
      this._securityService.generateUserReport(this.filter)
      .then(response => {
        const blobParts = this.$util.base64ToBytesArray(response.data);
        const blob = new Blob(blobParts, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64" })

        const url = window.URL.createObjectURL(blob);

        // Criar um link para o download e clicar nele para iniciar o download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Visão Gerencial dos Acessos.xlsx';
        link.click();
      })
      .catch(() => this.showmsg({text: 'Ocorreu um erro ao exportar o relatório.', type: 'error'}))
      .finally(() => this.loadingSearch=false);

    },
    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if(response && response.data) {
              localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    }
  },
  async created() {
    this._contractService = new ContractService();
    this._securityService = new SecurityService();
    const {token} = this.$route.query;
    if(token) {
      localStorage.setItem("LOCALIZED_TOKEN", token);
      localStorage.removeItem("auth_user");
      localStorage.removeItem("token");
      await this.loadJWTToken();
    }
    await this.loadJWTToken();

    this._contractService.FindFinancialGroupPermiteds()
    .then(response => {
      this.financialGroups = response.data;
    })
    .catch(() => this.showmsg({text: 'Ocorreu um erro ao carregar os grupos financeiros.', type: 'error'}));

    await this._securityService.FindAllProfilesPermitteds()
        .then((response) => {
          if (response.data && response.data.length) {
            this.profiles = response.data;
          }
        })
        .catch(() => {
          this.showmsg({text: 'Ocorreu um erro ao carregar os perfis de usuário.', type: 'error'})
        });
  }
};
</script>
