<template>
  <v-container fluid>
    <PreviousPage :route="'atendimentos'" :parameters="{'selectedTab': 'tab-list'}" />
    <v-card :loading="busy" class="elevation-0">
      <v-row>
        <v-col cols="12" md="6" class="pt-0" >
            <v-card >
              <v-card-title class="primary--text">
                Detalhes
              </v-card-title>
              <v-card-text v-if="!busy">
                <v-row dense class="mb-4">
                  <template v-for="header in headers">
                    <v-col v-if="showCondition(header.text, item[header.value])" cols="12" md="6" :key="header.value">
                      <v-text-field :label="header.text" :value="item[header.value]" readonly dense outlined></v-text-field>
                    </v-col>
                  </template>
                  <v-col :cols="item.externalProtocol ? '6' : '12'" class="px-4">
                    <v-row >
                      <v-text-field label="Atendido" :value="attendee" readonly dense outlined></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <v-row>
                      <v-textarea label="Descrição" :value="item.description" readonly dense auto-grow outlined></v-textarea>
                    </v-row>
                  </v-col>
                  <v-col cols="6" v-if="viewControlActivated">
                    <v-select
                        readonly
                        :items="viewerPermissionTypes"
                        v-model="item.viewerPermissionTypeCode"
                        item-value="code"
                        item-text="description"
                        label="Quem pode visualizar?"
                        placeholder="Não Informado!"
                        dense
                        outlined
                    />
                  </v-col>
                  <v-col cols="6" v-if="viewControlActivated">
                    <v-select
                        readonly
                        v-if="item.viewerPermissionTypeCode==='g'"
                        :items="users"
                        item-value="id"
                        item-text="name"
                        v-model="item.users"
                        label="Usuários"
                        dense
                        outlined
                        multiple
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="pt-0" >
            <!--timeline of case events-->
            <time-line :caseId="id" :externalId="externalId" />
          </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CaseService from "../../services/odoo/CaseService";
import ContractService from "../../services/odoo/ContractService";
import SecurityService from "../../services/security/SecurityService";
import TimeLine from "../../components/cases/TimeLine";
import PreviousPage from "../../components/structure/previousPage";
import Vue from "vue";
import {findAllUsersByFinancialGroup} from "@/common/securityserver";

export default {
  name: "AtendimentoDetalhar",
  components: {PreviousPage, TimeLine },
  props: {
    id: {
      type: Number,
      required: true
    },
    externalId: {
      type: String,
      required: false
    },
    appCode:{
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    item: {},
    users: [],
    headers: [],
    busy: true,
    viewControlActivated: false,
    viewerPermissionTypes: [{code:"m", description:"Minha Organização"},{code:"p", description:"Privado (somente eu)"},{code:"g", description:"Grupo"}],
    selectedTab: "comments",
    limiteDateExternaProtocol: '',
  }),
  computed: {
    ...mapGetters(["user"]),
    attendee() {
      return this.item.attendee;
    }
  },
  methods: {
    ...mapMutations(["showmsg"]),
    async configureItem(itemData) {

      const caseUsers = await this._securityService.findCaseUsersByCaseId(itemData.id);
      this.item = {
        protocol: itemData.id,
        externalProtocol: itemData.xipp_external_protocol ? itemData.xipp_external_protocol : null,
        createDate: Vue.filter('formatDate')(itemData.create_date),
        accountName: itemData.partner_id.name,
        suppliedName: itemData.partner_name,
        status: itemData.xipp_helpdesk_display_stage ? itemData.xipp_helpdesk_display_stage : itemData.stage_id,
        description: itemData.description ? itemData.description : " ",
        attendee: itemData.xipp_attendee_name ? itemData.xipp_attendee_name : " ",
        type: itemData.ticket_type_id ? itemData.ticket_type_id : " ",
        subject: itemData.name,
        origin: itemData.xipp_origin_id ? itemData.xipp_origin_id : " ",
        resolution: itemData.xipp_resolution ? itemData.xipp_resolution : null,
        xipp_external_protocol: itemData.xipp_external_protocol,
        viewerPermissionTypeCode: itemData.xipp_viewer_permission_type_id ? itemData.xipp_viewer_permission_type_id.code : null,
        users: caseUsers.data ? caseUsers.data.map(u => u.id.userId) : null
      };
      const contract = itemData.xipp_contract_id;
      if (contract) {
        this.item.benefit = itemData.xipp_benefit_id ? itemData.xipp_benefit_id : "";

        this.item.benefit += contract.carrier_id ? " / " + contract.carrier_id.xipp_commercial_name : " ";

        if (contract.policy) {
          if(this.appCode && this.appCode == "eu_protegido"){
            this.item.accountName = contract.policy + " - " + this.item.accountName;
          }else{
            this.item.accountName = contract.policy + " - " + this.item.accountName;
          }
        }
      }

    },
    async getCasePorId() {
      this.busy = true;
      const params = new URLSearchParams();
      params.append('id', this.id.toString())
      if(this.appCode){
        params.append('clientViewPermission',this.appCode)
      }
      params.append('fields', ['id','name','xipp_external_protocol','create_date','partner_id',
        'partner_id.name','partner_name','stage_id','xipp_helpdesk_display_stage','description','xipp_attendee_name','ticket_type_id','xipp_origin_id'
        ,'xipp_resolution', 'xipp_viewer_permission_type_id.code','xipp_contract_id','xipp_contract_id.carrier_id',
        'xipp_contract_id.xipp_commercial_name','xipp_contract_id.policy','xipp_benefit_id','xipp_viewer_permission_type_id',
        'xipp_viewer_permission_type_id.code']);
      await this._caseService
        .FindByIdV2(params)
          .then(res => {
          this.configureItem(res.data.content[0]);
        })
        .finally(() => (this.busy = false));
    },

    showCondition(headerText, value) {
      if (headerText.includes('Protocolo - Atendimentos abertos até')) {
        return value ? true : false;
      }else {
        return true;
      }
    },

    async loadLimiteDateExternalProtocol() {
      await this._caseService.FindLimiteDateExternalProtocol()
          .then((response) => {
            if(response  && response.data) {
              this.limiteDateExternaProtocol = response.data;
            }
          });
    },

    async loadHedears(){
      await this.loadLimiteDateExternalProtocol();

      if(this.appCode && this.appCode == "eu_protegido") {
        this.headers = [
          {text: "Solicitação", value: "subject", bold: true},
          {text: "Protocolo", value: "protocol", bold: true},
          {text: "Protocolo - Atendimentos abertos até " + this.limiteDateExternaProtocol, value: "externalProtocol", bold: true},
          {text: "Status", value: "status"},
          {text: "Contrato", value: "accountName"},
          {text: "Benefício / Operadora", value: "benefit"},
          {text: "Abertura", value: "createDate"}
        ]
      }else{
        this.headers = [
          {text: "Solicitação", value: "subject", bold: true},
          {text: "Protocolo", value: "protocol", bold: true},
          {text: "Protocolo - Atendimentos abertos até " + this.limiteDateExternaProtocol, value: "externalProtocol", bold: true},
          {text: "Status", value: "status"},
          {text: "Contrato", value: "accountName"},
          {text: "Benefício / Operadora", value: "benefit"},
          {text: "Abertura", value: "createDate"}
        ]
      }
    },
  },
  async created() {
    this._caseService = new CaseService();
    this._contractService = new ContractService();
    this._securityService = new SecurityService();
    var currentUser = this.$util.getSession();
    currentUser.profiles.forEach(profile => {
      if(profile.name=='Master' || profile.name=='Administrador' || profile.name=='Operacional'){
        this.viewControlActivated = true;
      }
    });
    await this.loadHedears();
    await this.getCasePorId();
  },
  async beforeMount() {
    this.users =  await findAllUsersByFinancialGroup(this.user.tokenSecurityService);
    this.users.sort( (u1, u2 ) => {
      if ( u1.name < u2.name){
        return -1;
      }
      if ( u1.name > u2.name ){
        return 1;
      }
      return 0;
    });
  }
};
</script>
