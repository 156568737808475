import Vue from 'vue'

import Vuex from 'vuex'
Vue.use(Vuex)

import merge from 'deepmerge';

import app from './app.js';
import assessment from './assessment.js';
import msg from './msg.js';
import breadcrumb from './breadcrumb.js';
import menu from './menu.js';
import window from './window.js';
import session from './session.js';
import filters from './filters.js';
import users from './users.js';
import benefitiaries from "./benefitiaries";
import notificationConfig from "./notificationConfig";
import posts from "./posts.js";

import invoice from './services/invoice';
import contract from './services/contract';
import security from "./services/security";

const mainStore = {
  strict: true,
}

const finalStore = merge.all([
  mainStore,
  app,
  assessment,
  msg,
  breadcrumb,
  menu,
  window,
  session,
  filters,
  users,
  invoice,
  contract,
  security,
  users,
  benefitiaries,
  notificationConfig,
  posts,
]);


export default new Vuex.Store(finalStore);
