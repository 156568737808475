var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PreviousPage'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary--text"},[_c('span',[_vm._v("Configuração das Notificações")]),_c('v-spacer'),(!_vm.isLoadingContent)?_c('v-btn',{attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.$router.push({ name: _vm.routeConfigureNotification, params: { id: 0 } })}}},[_c('v-icon',[_vm._v("fas fa-plus")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"file-explorer-desktop",attrs:{"headers":_vm.headers,"items":_vm.notificationConfigs,"loading":_vm.isLoadingContent,"item-key":"id","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Não existem configurações cadastradas. ")]},proxy:true},{key:"item.insertable",fn:function(ref){
var item = ref.item;
return [(item.insertable)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close-circle ")])]}},{key:"item.updatable",fn:function(ref){
var item = ref.item;
return [(item.updatable)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close-circle ")])]}},{key:"item.deletable",fn:function(ref){
var item = ref.item;
return [(item.deletable)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close-circle ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: _vm.routeConfigureNotification, params: {id: item.id}})}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteNotificationConfig(item.id)}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-delete-forever")])],1)]}}])},[(_vm.notificationConfigs && _vm.notificationConfigs.length > 0)?_c('template',{staticClass:"pl-2",slot:"body.append"},[_c('tr',{staticClass:"white--text controlscroll"},[_c('td',{attrs:{"colspan":_vm.headers.length}})])]):_vm._e()],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }